.content-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 240px;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--content-gap-desktop);
}

.text-section {
  display: flex;
  flex-direction: column;
  row-gap: var(--para-gap-desktop);
}

.banner {
  height: calc(100vh - var(--top-padding));
  min-height: 760px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-bottom: 1.5rem;
}

.banner-space {
  display: flex;
  justify-content: space-between; /* Elements at opposite ends with space in between */
  align-items: flex-end;
  width: 100%; /* Adjust the width of the container as needed */
}

.banner-image {
  min-height: 640px;
}

.innovators {
  display: flex;
  gap: var(--content-gap-mobile);
  align-items: center;
}

.tablet {
  display: none;
}

.innovators-image {
  width: 100%;
}

.innovators-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.grid-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--content-gap-mobile);
  width: 100%;
  align-items: center;
}

.loading-wrapper {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facilities-images {
  height: 480px;
  display: flex;
  gap: 24px;
}

.facilities-images > :nth-child(1) {
  flex: 2 0;
}

.facilities-images > :nth-child(2) {
  flex: 1 0;
}

.facilities-images-smaller {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.issues {
  width: 100%;
}

@media (max-width: 1200px) {
  .scroll-more {
    display: none;
  }
  .section-wrapper {
    row-gap: var(--content-gap-tablet);
  }
  .text-section {
    row-gap: var(--para-gap-tablet);
  }
  .banner {
    height: auto;
    min-height: unset;
  }
  .content-wrapper {
    row-gap: 180px;
  }
  .innovators {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }
  .tablet {
    display: unset;
  }
  .tablet-hide {
    display: none;
  }
  .innovators-image {
    aspect-ratio: 2;
  }
  .facilities-images > :nth-child(n) {
    flex: 1 0;
  }
}

@media (max-width: 640px) {
  .section-wrapper {
    row-gap: var(--content-gap-mobile);
  }
  .text-section {
    row-gap: var(--para-gap-mobile);
  }
  .content-wrapper {
    row-gap: 90px;
  }
  .banner-image {
    min-height: 320px;
  }
  .innovators-image {
    aspect-ratio: unset;
  }
  .facilities-images {
    flex-direction: column;
    height: unset;
  }
  .facilities-images-smaller {
    display: none;
  }
}
