footer {
  background-color: #284d89;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.footer-wrapper {
  max-width: 1440px;
  display: flex;
  width: 100%;

  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 3rem;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
}

footer p {
  color: #ffffff;
}

.social-media {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.media-buttons {
  display: flex;
  gap: 0.75rem;
}

footer img {
  width: 100%;
  height: 100%;
}

.social-media a {
  transition: 0.3s ease;
  width: 2.25rem;
  height: 2.25rem;
}

.social-media a:hover {
  opacity: 0.6;
}
