.heading {
  height: calc(100vh - var(--top-padding));
  min-height: 760px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 1.5rem;
  gap: var(--content-gap-mobile);
}

.subheader-space {
  font-style: italic;
  margin-bottom: 0px;
  margin-right: 0px;
  display: flex;
}

.heading-space {
  display: flex;
  justify-content: space-between; /* Elements at opposite ends with space in between */
  flex-wrap: wrap;
  align-items: center;
  width: 100%; /* Adjust the width of the container as needed */
  gap: 1.5rem;
}

.hero-image {
  min-height: 640px;
}

@media (max-width: 1200px) {
  .heading {
    height: auto;
    min-height: unset;
  }
  .hero-image {
    max-height: 820px;
  }
}

@media (max-width: 640px) {
  .hero-image {
    min-height: 320px;
    max-height: 480px;
  }
}
