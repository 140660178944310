.accordion-item {
  overflow: hidden;
  background-color: var(--white);
}

.accordion-item:first-child {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.accordion-item:last-child {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.accordion-item:has(:focus-visible) {
  position: relative;
  z-index: 1;
  outline: 2px solid var(--garage-blue);
}

.accordion-header {
  display: flex;
  margin: 0;
}

.accordion-trigger {
  font-family: inherit;
  padding-left: 32px;
  padding-right: 24px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  border: none;
}

.accordion-content {
  overflow: hidden;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.accordion-content[data-state="open"] {
  animation: slideDown 0.3s cubic-bezier(0, 0, 0, 1);
}
.accordion-content[data-state="closed"] {
  animation: slideUp 0.3s cubic-bezier(0, 0, 0, 1);
}

.accordion-content-wrapper {
  padding: 1rem 2rem;
  padding-top: 0.5rem;
}

.accordion-content[data-state="open"] > .accordion-content-wrapper {
  animation: moveIn 0.3s cubic-bezier(0, 0, 0, 1);
  animation-fill-mode: backwards;
}
.accordion-content[data-state="closed"] > .accordion-content-wrapper {
  animation: moveOut 0.3s cubic-bezier(0, 0, 0, 1);
  animation-fill-mode: forwards;
}

.accordion-chevron {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.accordion-trigger[data-state="open"] > .accordion-chevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes moveIn {
  from {
    transform: translateY(2rem);
  }
  to {
    transform: unset;
  }
}

@keyframes moveOut {
  from {
    transform: unset;
  }
  to {
    transform: translateY(2rem);
  }
}
