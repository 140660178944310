.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.image {
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
}

.loaded {
  opacity: 1;
}

.image-wrapper {
  border-radius: 2rem;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

@media (max-width: 640px) {
  .image-wrapper {
    border-radius: 1.5rem;
  }
}
