.base {
  white-space: pre-line;
}

.banner {
  font-size: 96px;
  line-height: 112px;
  font-weight: 500;
  letter-spacing: -2px;
}

.heading {
  font-size: 64px;
  line-height: 80px;
  font-weight: 500;
  letter-spacing: -1.5px;
}
.smallHeading {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}
.body {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.subtitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .banner {
    font-size: 64px;
    line-height: 80px;
    letter-spacing: -1.5px;
  }
  .heading {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -1px;
  }
  .smallHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .body {
    font-size: 18px;
    line-height: 28px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 640px) {
  .banner {
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -1px;
  }
  .heading {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.25px;
  }
  .smallHeading {
    font-size: 20px;
    line-height: 28px;
  }
  .body {
    font-size: 16px;
    line-height: 24px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}
