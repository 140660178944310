.link-preview {
  background-color: var(--white);
  color: var(--body);
  display: flex;
  border-radius: 2rem;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  transition: box-shadow 0.5s;
}

.link-preview:hover {
  color: var(--body);
  box-shadow: 0 0px 50px -12px rgb(0 0 0 / 0.4);
}

.link-preview:link {
  text-decoration: none;
}

.link-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}

.link-preview img {
  width: 96px;
  aspect-ratio: 1;
  border-radius: 1.25rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 640px) {
  .link-preview {
    border-radius: 1.5rem;
  }
  .link-preview img {
    width: 64px;
    border-radius: 0.75rem;
  }
}
