.btn {
  padding: 0 32px;
  border-radius: 100px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 640px) {
  .btn {
    height: 48px;
  }
}

.outlined {
  border: 3px solid var(--Body, #1a1a1a);
  color: #1a1a1a;
  background-color: transparent;
}

.outlined:hover {
  border-color: black;
  color: black;
  background-color: var(--outline-hover);
}

.outlined:active {
  border-color: black;
  color: black;
  background-color: var(--outline-press);
}

.filled {
  border: 0;
  background-color: var(--garage-blue, #284d8a);
  color: var(--white);
}

.filled:hover {
  background-color: var(--garage-blue-hover);
}

.filled:active {
  background-color: var(--garage-blue-press);
}

.outlined.disabled {
  border: 4px solid var(--Disabled, #d9d9d9);
  color: var(--onDisabled, #737373);
}

.outlined.disabled:hover {
  background-color: transparent;
}

.outlined.disabled:active {
  background-color: transparent;
}

.filled.disabled {
  background-color: var(--Disabled, #d9d9d9);
  color: var(--onDisabled, #737373);
}

.filled.disabled:hover {
  background-color: var(--Disabled, #d9d9d9);
}

.filled.disabled:active {
  background-color: var(--Disabled, #d9d9d9);
}

.disabled {
  cursor: default;
}

.start-icon-wrapper {
  margin-right: 8px;
}

.end-icon-wrapper {
  margin-left: 8px;
}
