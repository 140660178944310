.gutter {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
}

.container {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 640px) {
    .gutter {
        padding-left: var(--gutter-mobile);
        padding-right: var(--gutter-mobile);
    }
}