.newsletter-wrapper {
  display: flex;
  padding-right: 2rem;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
}

.cover {
  height: 256px;
  border-radius: 0.75rem !important;
  width: unset !important;
}

.link {
  color: var(--body);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  flex: 1;
}

.link:hover {
  color: var(--body);
}
.link > * {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.link:hover > * {
  transform: translateX(20px);
}

@media (max-width: 640px) {
  .newsletter-wrapper {
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding-right: 0;
  }
  .chevron {
    display: none;
  }
  .link:hover > * {
    transform: unset;
  }
  .text {
    text-align: center;
    color: inherit;
  }
}
