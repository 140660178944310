/* OVERALL STYLING */
* {
  box-sizing: border-box;
  scrollbar-color: var(--table-disabled) var(--background);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
}

:root {
  --garage-blue: #284d8a;
  --garage-blue-hover: #204279;
  --garage-blue-press: #193462;
  --white: #ffffff;
  --body: #1a1a1a;
  --background: #f6f6f6;
  --outline: #cbcbcb;
  --outline-hover: #1a1a1a1a;
  --outline-press: #1a1a1a33;
  --disabled: #d9d9d9;
  --on-disabled: #737373;
  --error: #e10d0d;
  --table-disabled: #a3b4cc;
  --table-accent: #e5f1ff;

  --max-width: 1440px;
  --header-height: 60px;
  --gutter: 24px;
  --gutter-mobile: 16px;
  --content-gap-desktop: 80px;
  --content-gap-tablet: 60px;
  --content-gap-mobile: 40px;
  --para-gap-desktop: 24px;
  --para-gap-tablet: 20px;
  --para-gap-mobile: 18px;
  --top-padding: calc(60px + var(--header-height));
  --top-padding-mobile: calc(40px + var(--header-height));
}

:root:has(.modal) {
  overflow: hidden;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
