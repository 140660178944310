.header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 8;
  transition: background-color 0.4s, filter 0.4s;
  background-color: var(--background);
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--content-gap-mobile);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.logo {
  height: 36px;
}

.nav {
  display: flex;
  gap: var(--content-gap-mobile);
}

.navlink {
  color: var(--body);
  position: relative;
  display: block;
}

.navlink:hover {
  color: var(--body);
}

.navlink::after {
  position: absolute;
  content: "";
  display: block;
  transition: width 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  height: 2px;
  background-color: var(--body);
  width: 0%;
}

.navlink:hover::after {
  width: 100%;
}

.button {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  width: 36px;
  height: 36px;
}

.drawer {
  width: 100%;
  overflow: hidden;
}

.drawer-inner {
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.shadow {
  background-color: var(--white);
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.separator {
  height: 1px;
  background-color: var(--outline);
  width: 0;
  animation: grow 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0.1s both;
}

.mobile-link {
  animation: slide-in 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  animation-fill-mode: both;
}

@keyframes grow {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(50px);
  }

  to {
    transform: translateX(0px);
  }
}
