.white {
  height: 100lvh;
  width: 100vw;
  position: fixed;
  background-color: var(--white);
  top: 0;
  left: 0;
  z-index: 11;
}

.blue {
  height: 100lvh;
  width: 100vw;
  position: fixed;
  background-color: var(--garage-blue);
  top: 0;
  left: 0;
  z-index: 12;
}

.overlay {
  height: 100lvh;
  width: 100vw;
  position: fixed;
  background-color: black;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
}

.loading-text {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -1.5px;
  color: var(--white);
  z-index: 13;
  pointer-events: none;
}
