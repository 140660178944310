/* Card Styles */
.card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card {
  overflow: hidden;
  border-radius: 2rem;
  transition: box-shadow 0.5s;
  position: relative;
  aspect-ratio: 1;
}

.card-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.text-overlay {
  position: absolute;
  top: 32px; /* Adjust as needed to position the text */
  left: 32px; /* Adjust as needed to position the text */
  color: var(--body); /* Text color */
  z-index: 1; /* Ensure the text appears on top of the image */
  max-width: 60%;
}

.bottom-text {
  padding-bottom: calc(var(--content-gap-mobile) - var(--gutter));
}

.link {
  color: var(--body);
}
.link:hover {
  color: var(--body);
}
.link:hover .card-image {
  transform: scale(1.02);
}
.link:hover .card {
  box-shadow: 0 0px 50px -12px rgb(0 0 0 / 0.4);
}

.card:hover {
  box-shadow: 0 0px 50px -12px rgb(0 0 0 / 0.4);
}
.card:hover .card-image {
  transform: scale(1.02);
}

@media (max-width: 1200px) {
  .card-wrapper {
    gap: 1rem;
  }
}

@media (max-width: 640px) {
  .card {
    border-radius: 1.5rem;
  }
  .card-wrapper {
    gap: 0.75rem;
  }
}
