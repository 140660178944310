.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--content-gap-desktop);
}

@media (max-width: 1200px) {
  .wrapper {
    gap: var(--content-gap-tablet);
  }
}

@media (max-width: 640px) {
  .wrapper {
    gap: var(--content-gap-mobile);
  }
}
